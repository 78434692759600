import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import VueClipboard from 'vue-clipboard2'
import VueSocialSharing from 'vue-social-sharing'
import VSwitch from 'v-switch-case'
import VueYouTubeEmbed from 'vue-youtube-embed'
import JsonExcel from "vue-json-excel"
import VueMeta from 'vue-meta'



import '../src/css/style.css';
import "./vee-validate";

Vue.config.productionTip = false

/* Font Awesome setting */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCalendarCheck, faCalendarPlus, faPersonRunning, faCloudMoon, faCloudSun, faSun, faPen, faShare, faUsers, faEye, faCircleCheck, faFileInvoice, faMapLocationDot, faBell, faFloppyDisk, faArrowLeft, faArrowRight, faBoxesStacked, faPiggyBank, faUserPlus, faFileArrowDown, faGift, faBackward, faPlay, faForward, faPause, faCross } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faWaze, faPinterest, faInstagram } from '@fortawesome/free-brands-svg-icons'
library.add(faCalendarCheck, faCalendarPlus, faPersonRunning, faCloudMoon, faCloudSun, faSun, faPen, faShare, faWhatsapp, faUsers, faEye, faCircleCheck, faWaze, faFileInvoice, faMapLocationDot, faBell, faFloppyDisk, faArrowLeft, faArrowRight, faBoxesStacked, faPinterest, faPiggyBank, faUserPlus, faFileArrowDown, faGift, faBackward, faPlay, faForward, faPause, faInstagram, faCross)
Vue.component('font-awesome-icon', FontAwesomeIcon)

/* datatable */
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';

Vue.use(VueCookies);
Vue.use(VueClipboard);
Vue.use(VueSocialSharing);
Vue.use(VSwitch)
Vue.use(VueYouTubeEmbed)
Vue.use(VueMeta)
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
